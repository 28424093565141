var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "ml-6 mt-2", attrs: { cols: "12" } },
                    [_c("h3", [_vm._v(_vm._s(this.siteName))])]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "ml-2" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "card-shadow border-radius-xl py-4" },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "px-4",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c("v-col", { attrs: { sm: "4" } }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "\n                    text-sm\n                    mb-1\n                    text-capitalize text-body\n                    font-weight-bold\n                  ",
                                  },
                                  [_vm._v(" Quarterly Savings ")]
                                ),
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "text-h5 text-typo font-weight-bolder mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " $" + _vm._s(this.quarterSavings) + " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "v-col",
                                { staticClass: "text-end", attrs: { sm: "8" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-s text-secondary font-weight-light",
                                    },
                                    [
                                      _vm._v(
                                        " Q" +
                                          _vm._s(this.selectedQuarter) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "card-shadow border-radius-xl py-4" },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "px-4",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c("v-col", { attrs: { sm: "4" } }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "\n                    text-sm\n                    mb-1\n                    text-capitalize text-body\n                    font-weight-bold\n                  ",
                                  },
                                  [_vm._v(" Last 12 Months Savings ")]
                                ),
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "text-h5 text-typo font-weight-bolder mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " $" + _vm._s(this.yearSavings) + " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "v-col",
                                { staticClass: "text-end", attrs: { sm: "8" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-s text-secondary font-weight-light",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(this.selectedQuarterYear) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "ml-2 mt-1" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: {
                            "background-color": "transparent",
                            grow: "",
                          },
                          model: {
                            value: _vm.NewTab,
                            callback: function ($$v) {
                              _vm.NewTab = $$v
                            },
                            expression: "NewTab",
                          },
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              attrs: { href: "#metrics_quarter" },
                              on: {
                                change: function ($event) {
                                  return _vm.selectQuarterlyTab()
                                },
                              },
                            },
                            [_vm._v(" Quarter ")]
                          ),
                          _c(
                            "v-tab",
                            {
                              attrs: { href: "#metrics_year_new" },
                              on: {
                                change: function ($event) {
                                  return _vm.selectYearlyTab()
                                },
                              },
                            },
                            [_vm._v(" Year ")]
                          ),
                          _c(
                            "v-tab",
                            {
                              attrs: { href: "#metrics_all_time" },
                              on: {
                                change: function ($event) {
                                  return _vm.selectAllTimeTab()
                                },
                              },
                            },
                            [_vm._v(" All Time ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.NewTab,
                            callback: function ($$v) {
                              _vm.NewTab = $$v
                            },
                            expression: "NewTab",
                          },
                        },
                        [
                          _c(
                            "v-tab-item",
                            { attrs: { value: "metrics_quarter" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "card-shadow border-radius-xl h-100",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "px-4 py-4",
                                      attrs: { id: "chart" },
                                    },
                                    [
                                      _c("apexchart", {
                                        attrs: {
                                          height: "400",
                                          type: "line",
                                          options: _vm.quarterlyOptions,
                                          series: _vm.series,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            { attrs: { value: "metrics_year_new" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "card-shadow border-radius-xl h-100",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "px-4 py-4",
                                      attrs: { id: "year-data-new" },
                                    },
                                    [
                                      _c("apexchart", {
                                        attrs: {
                                          height: "400",
                                          type: "line",
                                          options: _vm.testOptions,
                                          series: _vm.yearseries,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            { attrs: { value: "metrics_all_time" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "card-shadow border-radius-xl h-100",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "px-4 py-4" },
                                    [
                                      _c("apexchart", {
                                        attrs: {
                                          height: "400",
                                          type: "bar",
                                          options: _vm.allTimeOptions,
                                          series: _vm.series,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "ml-2" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "border-radius-xl card-shadow" },
                        [
                          _c("div", { staticClass: "px-6 py-6 text-center" }, [
                            _c(
                              "h1",
                              {
                                staticClass:
                                  "text-gradient text-primary font-weight-bolder text-h1",
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(this.thirtyDayHealth) + "%"),
                                ]),
                              ]
                            ),
                            _c(
                              "h6",
                              {
                                staticClass:
                                  "mb-0 text-h6 font-weight-bolder text-typo",
                              },
                              [_vm._v("30 Days")]
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "opacity-8 mb-0 text-sm text-typo",
                              },
                              [_vm._v("System Health")]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "border-radius-xl card-shadow" },
                        [
                          _c("div", { staticClass: "px-6 py-6 text-center" }, [
                            _c(
                              "h1",
                              {
                                staticClass:
                                  "text-gradient text-primary font-weight-bolder text-h1",
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(this.ninetyDayHealth) + "%"),
                                ]),
                              ]
                            ),
                            _c(
                              "h6",
                              {
                                staticClass:
                                  "mb-0 text-h6 font-weight-bolder text-typo",
                              },
                              [_vm._v("90 Days")]
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "opacity-8 mb-0 text-sm text-typo",
                              },
                              [_vm._v("System Health")]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "border-radius-xl card-shadow" },
                        [
                          _c("div", { staticClass: "px-6 py-6 text-center" }, [
                            _c(
                              "h1",
                              {
                                staticClass:
                                  "text-gradient text-primary font-weight-bolder text-h1",
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(this.yearHealth) + "%"),
                                ]),
                              ]
                            ),
                            _c(
                              "h6",
                              {
                                staticClass:
                                  "mb-0 text-h6 font-weight-bolder text-typo",
                              },
                              [_vm._v("365 Days")]
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "opacity-8 mb-0 text-sm text-typo",
                              },
                              [_vm._v("System Health")]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                { staticClass: "mr-2 mt-12" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "card-shadow border-radius-xl py-4" },
                        [
                          _c(
                            "h6",
                            {
                              staticClass:
                                "mb-0 text-h6 text-typo font-weight-bold px-4",
                            },
                            [_vm._v(" Case History ")]
                          ),
                          [
                            _c("div", { staticClass: "container" }, [
                              _c("div", { staticClass: "row mb-3" }),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "ul",
                                    {
                                      staticClass: "list-group",
                                      staticStyle: { "padding-left": "0px" },
                                    },
                                    [
                                      _vm.cases.length > 0
                                        ? _c("report-case", {
                                            key: _vm.site_id,
                                            attrs: {
                                              sitecases: _vm.cases,
                                              quarter: _vm.selectedQuarter,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]),
                          ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }