<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-row>
          <v-col class="ml-6 mt-2" cols="12">
            <h3>{{ this.siteName }}</h3>
          </v-col>
        </v-row>
        <v-row class="ml-2">
          <v-col cols="6">
            <v-card class="card-shadow border-radius-xl py-4">
              <v-row no-gutters class="px-4">
                <v-col sm="4">
                  <p
                    class="
                      text-sm
                      mb-1
                      text-capitalize text-body
                      font-weight-bold
                    "
                  >
                    Quarterly Savings
                  </p>
                  <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                    ${{ this.quarterSavings }}
                  </h5>
                </v-col>
                <v-col sm="8" class="text-end">
                  <span class="text-s text-secondary font-weight-light">
                    Q{{this.selectedQuarter}}
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card class="card-shadow border-radius-xl py-4">
              <v-row no-gutters class="px-4">
                <v-col sm="4">
                  <p
                    class="
                      text-sm
                      mb-1
                      text-capitalize text-body
                      font-weight-bold
                    "
                  >
                    Last 12 Months Savings
                  </p>
                  <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                    ${{ this.yearSavings }}
                  </h5>
                </v-col>
                <v-col sm="8" class="text-end">
                  <span class="text-s text-secondary font-weight-light">
                    {{this.selectedQuarterYear}}
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="ml-2 mt-1">
          <v-col cols="12">
            <v-tabs background-color="transparent" grow v-model="NewTab">
              <v-tab href="#metrics_quarter" @change="selectQuarterlyTab()">
                Quarter
              </v-tab>
              <!-- <v-tab href="#metrics_month" @change="selectMonthlyTab()">
                Month
              </v-tab> -->
              <!-- <v-tab href="#metrics_year" @change="selectYearlyTab()">
                Year
              </v-tab> -->
              <v-tab href="#metrics_year_new" @change="selectYearlyTab()">
                Year
              </v-tab>
              <v-tab href="#metrics_all_time" @change="selectAllTimeTab()">
              All Time
            </v-tab>
            </v-tabs>
            <v-tabs-items v-model="NewTab">
              <!-- quarter -->
              <v-tab-item value="metrics_quarter">
                <v-card class="card-shadow border-radius-xl h-100">
                  <div id="chart" class="px-4 py-4">
                    <apexchart
                      height="400"
                      type="line"
                      :options="quarterlyOptions"
                      :series="series"
                    ></apexchart>
                  </div>
                </v-card>
              </v-tab-item>
              <!-- month -->
              <!-- <v-tab-item value="metrics_month">
                <v-card class="card-shadow border-radius-xl h-100">
                  <div class="px-4 py-4">
                    <apexchart
                      height="400"
                      type="bar"
                      :options="options"
                      :series="series"
                    ></apexchart>
                  </div>
                </v-card>
              </v-tab-item> -->
              <!-- year -->
              <!-- <v-tab-item value="metrics_year">
                <v-card class="card-shadow border-radius-xl h-100">
                  <div id="year-data" class="px-4 py-4">
                    <apexchart
                      height="400"
                      type="line"
                      :options="testOptions"
                      :series="series"
                    ></apexchart>
                  </div>
                </v-card>
              </v-tab-item> -->
              <v-tab-item value="metrics_year_new">
                <v-card class="card-shadow border-radius-xl h-100">
                  <div id="year-data-new" class="px-4 py-4">
                    <apexchart
                      height="400"
                      type="line"
                      :options="testOptions"
                      :series="yearseries"
                    ></apexchart>
                  </div>
                </v-card>
              </v-tab-item>
              <v-tab-item value="metrics_all_time">
              <v-card class="card-shadow border-radius-xl h-100">
                <div class="px-4 py-4">
                  <apexchart
                    height="400"
                    type="bar"
                    :options="allTimeOptions"
                    :series="series"
                  ></apexchart>
                </div>
              </v-card>
            </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
        <v-row class="ml-2">
          <v-col cols="4">
            <v-card class="border-radius-xl card-shadow">
              <div class="px-6 py-6 text-center">
                <h1 class="text-gradient text-primary font-weight-bolder text-h1">
                  <span>{{ this.thirtyDayHealth }}%</span>
                </h1>
                <h6 class="mb-0 text-h6 font-weight-bolder text-typo">30 Days</h6>
                <p class="opacity-8 mb-0 text-sm text-typo">System Health</p>
              </div>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card class="border-radius-xl card-shadow">
              <div class="px-6 py-6 text-center">
                <h1 class="text-gradient text-primary font-weight-bolder text-h1">
                  <span>{{ this.ninetyDayHealth }}%</span>
                </h1>
                <h6 class="mb-0 text-h6 font-weight-bolder text-typo">90 Days</h6>
                <p class="opacity-8 mb-0 text-sm text-typo">System Health</p>
              </div>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card class="border-radius-xl card-shadow">
              <div class="px-6 py-6 text-center">
                <h1 class="text-gradient text-primary font-weight-bolder text-h1">
                  <span>{{ this.yearHealth }}%</span>
                </h1>
                <h6 class="mb-0 text-h6 font-weight-bolder text-typo">365 Days</h6>
                <p class="opacity-8 mb-0 text-sm text-typo">System Health</p>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row class="mr-2 mt-12">
          <v-col cols="12">
            <v-card class="card-shadow border-radius-xl py-4">
              <h6 class="mb-0 text-h6 text-typo font-weight-bold px-4">
                Case History
              </h6>
              <template>
                <div class="container">
                  <div class="row mb-3"></div>
                  <div class="row">
                    <div class="col-12">
                      <ul class="list-group" style="padding-left: 0px">
                        <report-case
                          v-if="cases.length > 0"
                          :sitecases="cases"
                          :key="site_id"
                          :quarter="selectedQuarter"
                        />
                      </ul>
                    </div>
                  </div>
                </div>
              </template>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
  
<script>
// import InverterList from "./InverterList.vue";
import Service from "@/services/Service.js";
import RoundSlider from "vue-round-slider";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import ReportCase from "@/components/ReportCase";
//   import ComparativeEnergy from "./ComparativeEnergy.vue";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

export default {
  components: {
    //   ComparativeEnergy,
    RoundSlider,
    ReportCase
  },
  props: ["site_id", "nativeLink", "system", "plans"],
  data() {
    return {
      tab: null,
      NewTab: null,
      sliderValue: "",
      kwhSelect: true,
      kwhKwpSelect: false,
      performanceSelect: false,
      estimateSelect: true,
      selectedYear: 2022,
      selectedMonth: null,
      selectedQuarter: null,
      selectedQuarterYear: null,
      selectedUploadKwhYear: null,
      selectedUploadKwhMonth: null,
      selectedUploadKwhAmount: null,
      selectedTab: "quarter",
      siteName: null,
      siteId: null,
      thirtyDayHealth: null,
      ninetyDayHealth: null,
      yearHealth: null,
      quarterSavings: null,
      yearSavings: null,
      cases: [],
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      allTimeOptions: {
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              hideOverflowingLabels: true,
              offsetY: 30,
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: false,
          style: {
            fontSize: "8px",
            colors: ["#fff"],
          },
        },
        chart: {
          id: "all-time-data",
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
      quarterlyOptions: {
        chart: {
          id: "quarterly-stats",
        },
        xaxis: {
          categories: ["Q1", "Q2", "Q3", "Q4"],
        },
      },
      testOptions: {
        chart: {
          id: "year-data-new",
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ]
        }
      },
      yearlyOptions: {
        chart: {
          id: "yearly-data",
          type: "line",
        },
        plotOptions: {
          bar: {
            columnWidth: "50%"
          },
        },
        stroke: {
          curve: "smooth",
          width: [0, 4],
        },
        markers: {
          size: 0,
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
      yearseries: [],
      rules: [(value) => !!value || "Required."],
    };
  },
  updated() {
    var title = this.siteName + ' Report'
    document.title = title
  },
  created: function () {
    // figure out dates
    var quarterAdjustment= (this.$moment().month() % 3) + 1;
    var lastQuarterEndDate = this.$moment().subtract({ months: quarterAdjustment }).endOf('month');
    var lastQuarterStartDate = lastQuarterEndDate.clone().subtract(3, 'months').endOf('month').add(1,'days');
    this.selectedQuarter = this.getQuarterNum(lastQuarterEndDate);
    this.selectedQuarterYear = lastQuarterEndDate.year();
    this.decodeURL();
    this.getCases();
    this.setDates();
  },
  methods: {
    setDates() {
      var d = new Date();
      this.date = d;
      this.selectedMonth = d.getMonth();
      this.selectedYear = d.getFullYear();
      // this.getQuarterNum();
    },
    getQuarterNum(date) {
      return Math.floor(date.month() / 3 + 1);
    },
    loadCustomerMetrics() {
      NProgress.start()
      Service.getCustomerMetrics(this.siteId, this.token)
        .then((response) => {
          console.log(response.data)
          var stats = response.data.stats
          this.quarterSavings = stats.q_savings
          this.yearSavings = stats.year_savings
          this.thirtyDayHealth = stats.month_health
          this.ninetyDayHealth = stats.three_month_health
          this.yearHealth =  stats.year_health
          var quarterStats = response.data.all_time_qtr_data       
          //here
          const quarters = [1, 2, 3, 4];
          const transformedArray = [];
          quarterStats[1].forEach(data => {
            const quarterDataArray = quarters.map(quarterNumber => {
              const quarterData = quarterStats[quarterNumber].find(item => item.year === data.year);
              return quarterData || null;
            });

            transformedArray.push(quarterDataArray);
          });
          console.log(transformedArray);

          let allYears = Object.values(transformedArray);
          let yearlyData = allYears[allYears.length - 1];
          let s_comp = allYears.slice(0, -1);

          var siteCompEnergy = s_comp;

          this.quarterlyOptions = {
            chart: {
              id: "quarterly-stats",
            },
            xaxis: {
              categories: ['Q1', 'Q2', 'Q3', 'Q4']
            },
          };
          let kwhData = yearlyData.map((d) => {
            return parseFloat(d.kwh).toFixed(0);
          });
          // let displayYear = yearlyData[0].year
          
          let x = this.renderNewSeries(kwhData);

          for (let i = 0; i < siteCompEnergy.length; i++) {
            let kwhData = siteCompEnergy[i].map((k) => {
              return k.kwh === null ? 0 : parseFloat(k.kwh).toFixed(0);
            });
            x.push({
              name: `${siteCompEnergy[i][0].year}`,
              type: "line",
              data: kwhData,
            });
          }
          this.series = x;
          NProgress.done()
        })
        .catch((error) => {
          NProgress.done()
          console.log(error)
        });
    },
    getCases() {
      Service.getCasesandSitesSc(this.siteId, this.token)
        .then((response) => {
          this.cases = response[0].data;
          this.siteName = response[1].data.name
          this.loadCustomerMetrics();
        })
        .catch((error) => {
          console.log("Error getting cases data:", error.response);
        });
    },
    decodeURL() {
      var getSegment = function (url, index) {
            return url.replace(/^https?:\/\//, '').split('?')[index];
          }
      var url = getSegment(window.location.href, 1)
      var newURL = url.slice(8)
      var final = newURL.split('&')
      this.siteId = parseInt(final[0])
      this.token = final[1].slice(6)
    },
    loadAllTimeMetrics() {
      NProgress.start();
      var that = this;
      Service.getSiteCompEnergyYearSc(this.siteId, this.token)
        .then((response) => {
          console.log(response.data);
          let newXAxis = response.data.alltime_data.map((d) => {
            return d.year;
          });
          console.log(newXAxis);
          this.allTimeOptions = {
            xaxis: {
              categories: newXAxis,
            },
          };
          let kwhData = response.data.alltime_data.map((d) => {
            return parseFloat(d.kwh).toFixed(0);
          });
          // let kwhEstimateData = response.data.alltime_data.map((d) => {
          //   // return Math.round(d.kwh * 100) / 100;
          //   return d.kwh_estimate;
          // });
          this.series = this.renderNewSeries(
            kwhData,
            // kwhEstimateData
          );
          NProgress.done();
        })
        .catch((error) => {
          console.error(error);
          NProgress.done();
        });
    },
    loadYearlyMetrics() {
      let year = this.selectedYear;
      NProgress.start();
      var that = this;
      Service.getSiteYearlyComboStatsSc(this.siteId, year, this.token)
        .then((response) => {
          let yearlyData = response[0].data;
          let s_comp = response[1].data;

          // Not sure why we were doing this... removing 
          // var siteCompEnergy = s_comp.filter(function (d) {
          //   return d.year != year;
          // });
          let lastObject = s_comp[s_comp.length - 1]; 
          if(lastObject.year == year) {
            var siteCompEnergy = s_comp.slice(0, -1);
          } else {
            var siteCompEnergy = s_comp;
          };

          let newXAxis = yearlyData.map((d) => {
            var d = new Date(`${d.date} 00:00:00`);
            return that.getMonthStrFromInt(d.getMonth());
          });
          this.testOptions = {
            chart: {
              id: "year-data-new",
            },
            xaxis: {
              categories: newXAxis,
            },
          };
          // that.yearlyOptions = {
          //   xaxis: {
          //     categories: newXAxis,
          //   },
          // };

          let kwhData = yearlyData.map((d) => {
            // return Math.round(d.kwh * 100) / 100;
            return parseFloat(d.kwh).toFixed(0);
          });

          let s = that.renderNewSeries(kwhData);

          for (let i = 0; i < siteCompEnergy.length; i++) {
            let kwhData = siteCompEnergy[i].data.map((k) => {
              return k.kwh === null ? 0 : parseFloat(k.kwh).toFixed(0);
            });
            s.push({
              name: `${siteCompEnergy[i].year.toString()}`,
              type: "line",
              data: kwhData,
            });
          }
          this.yearseries = s;
          NProgress.done();
        })
        .catch((error) => {
          console.error(error);
          NProgress.done();
        });
    },
    renderNewSeries(kwhData, perfData, kwhKwpData, kwhEstimateData) {
       return [{
        name: "kwh",
        type: "column",
        data: kwhData
      }];
    },
    selectQuarterlyTab() {
      this.selectedTab = "quarter";
      this.loadCustomerMetrics();
    },
    selectAllTimeTab() {
      this.selectedTab = "alltime";
      this.loadAllTimeMetrics();
    },
    selectYearlyTab() {
      this.selectedTab = "year";
      this.loadYearlyMetrics();
    },
    getMonthFromString(mon) {
      return new Date(Date.parse(mon + " 1, 2012")).getMonth() + 1;
    },
    getMonthStrFromInt(m) {
      return this.months[m];
    },
    // getQuarterNum() {
    //   if(this.selectedMonth == 1) {
    //       this.selectedQuarter = 1
    //   } else if(this.selectedMonth == 2) {
    //       this.selectedQuarter = 1
    //   } else if(this.selectedMonth == 3) {
    //       this.selectedQuarter = 1
    //   } else if(this.selectedMonth == 4) {
    //       this.selectedQuarter = 2
    //   } else if(this.selectedMonth == 5) {
    //       this.selectedQuarter = 2
    //   } else if(this.selectedMonth == 6) {
    //       this.selectedQuarter = 2
    //   } else if(this.selectedMonth == 7) {
    //       this.selectedQuarter = 3
    //   } else if(this.selectedMonth == 8) {
    //       this.selectedQuarter = 3
    //   } else if(this.selectedMonth == 9) {
    //       this.selectedQuarter = 3
    //   } else if(this.selectedMonth == 10) {
    //       this.selectedQuarter = 4
    //   } else if(this.selectedMonth == 11) {
    //       this.selectedQuarter = 4
    //   } else {
    //     this.selectedQuarter = 4
    //   }
    // },
  },
};
</script>
  
<style>
</style>
  